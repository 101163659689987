@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');
// fonst size
$fontSize16: 16px;
$fontSize18: 18px;
$fontSize22: 22px;
$fontSize20: 20px;
$fontSize24: 24px;
$fontSize26: 26px;
$fontSize30: 30px;
$fontSize35: 35px;
$fontSize55: 55px;

// responsive fontsize

$fontSize10: 10px;
$fontSize12: 12px;
$fontSize14: 14px;
// color
$red: #e43638;
$blue: #2b4c6f;
$white: #fff;
$black: #272727;
$black-2: #393939;
$light-grey: #89888a;
$border-color: #d9d9d9;
$border-color2: #dddddd;
$green-color: #388e3c;
$light-blue: #58aff6;
$alice-blue: #eff7fe;

$order-done-color: #36e4c5;
$order-done-bg: #d7faf3;
$order-approved-color: #e49436;
$order-approved-bg: #faead7;

// font-family
$font-family-rubik: 'Rubik', sans-serif;
$font-family-raleway: 'Raleway', sans-serif;
$font-family-montserrat: 'Montserrat', sans-serif;

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	//   &:not(i) {
	//     font-family: 'Lato', sans-serif;
	//   }
}
html {
	background: #ebebeb;
	font-family: $font-family-rubik;
}
body {
	margin: 0;
	font-family: $font-family-rubik;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: hidden;
}

.MuiTableCell-root{
    &.order-status-box {
        padding: 0;
    }
}
.MuiTypography-root,
.MuiTableContainer-root,
.MuiTableCell-root,
.MuiPaper-root,
.MuiCard-root {
	font-family: $font-family-rubik !important;
}

.hand {
	cursor: pointer !important;
}

.dis-inherit{
    display: inherit!important;
}

.verify-container {
	height: 100vh;
}
.sideNavBar {
	.listItemText {
		color: $blue;
		font-family: $font-family-rubik;
		font-style: normal;
		font-weight: normal;
		font-size: $fontSize16;
		line-height: 19px;
	}
	.logoutIcons path {
		fill: $blue;
	}
}

.privacy {
	h6 {
		font-weight: 500;
		color: $blue;
		margin: 0 0 1rem 0;
		font-style: normal;
		font-size: $fontSize16;
		span {
			font-family: $font-family-montserrat;
			&:after {
				background-color: $red;
				width: 45%;
				position: absolute;
				content: '';
				height: 5px;
				bottom: -20px;
				left: 0;
			}
		}
	}
	.last-updated {
		font-family: $font-family-rubik;
		font-style: italic;
		font-weight: 300;
		font-size: $fontSize16;
		line-height: 18px;
		color: $light-grey;
	}
	.content {
		font-family: $font-family-rubik;
		font-style: normal;
		font-weight: 300;
		font-size: $fontSize16;
		line-height: 150%;
		color: $black-2;
		.sub-heading {
			font-weight: 500;
			color: $blue;
		}
		.privacy-policy {
			font-style: italic;
		}
	}
}

.about-us {
	h6 {
		font-weight: 500;
		color: $blue;
		margin: 0 0 1rem 0;
		font-style: normal;
		font-size: $fontSize16;
		span {
			font-family: $font-family-montserrat;
			&:after {
				background-color: $red;
				width: 45%;
				position: absolute;
				content: '';
				height: 5px;
				bottom: -20px;
				left: 0;
			}
		}
	}
	pre {
		font-size: $fontSize16;
		font-family: 'Rubik', sans-serif;
		color: $black-2;
		font-weight: 300;
		span {
			font-style: normal;
			font-weight: normal;
			color: $blue;
		}
	}
	.content {
		font-family: $font-family-rubik;
		font-style: italic;
		font-weight: 300;
		font-size: $fontSize16;
		line-height: 150%;
		color: $black-2;
	}
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }

.research-and-surveys {
	.heading {
		font-weight: 500;
		color: $blue;
		font-style: normal;
		font-size: $fontSize16;
		span {
			font-family: $font-family-montserrat;
			&:after {
				background-color: $red;
				width: 45%;
				position: absolute;
				content: '';
				height: 5px;
				bottom: -20px;
				left: 0;
			}
		}
	}
	.desc {
		font-family: $font-family-montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: $fontSize14;
		line-height: 18px;
		color: $light-grey;
	}
}

.my-orders-and-results {
	.desc {
		font-family: $font-family-rubik;
		font-style: normal;
		font-weight: normal;
		font-size: $fontSize18;
		line-height: 21px;
		color: $light-grey;
	}
	.box {
		border: 1px $red;
		border-radius: 9px;
		border-style: dashed;
		.mh-400 {
			min-height: 350px;
		}
		.no-texts-ordered {
			font-family: $font-family-rubik;
			font-style: normal;
			font-weight: normal;
			font-size: $fontSize18;
			line-height: 21px;
			text-align: center;
			color: $blue;
			opacity: 0.5;
			margin: 0 0 1rem 0;
		}
		.order-now {
			font-family: $font-family-montserrat;
			font-style: normal;
			font-weight: 500;
			font-size: $fontSize16;
			line-height: 20px;
			text-align: center;
			color: $red;
			border: 1px solid;
			padding: 9px;
			border-radius: 20px;
		}
	}
}

.bb-15 {
	border-bottom: 5px solid $red;
	width: 15%;
	margin-top: 15px;
	border-radius: 20px;
}

// control styles

.error-block {
	margin: 3px 0 0 0;
	color: red;
	font-size: 12px;
}
.controller-outer {
	margin-bottom: 1.5rem;
	.MuiOutlinedInput-root {
		// - The Input-root, inside the TextField-root
		& fieldset {
			// - The <fieldset> inside the Input-root
			border-color: $border-color !important; // - Set the Input border
		}
		:hover fieldset {
			border-color: $border-color !important; // - Set the Input border when parent has :hover
		}
		.Mui-focused fieldset {
			// - Set the Input border when parent is focused
			border-color: $border-color !important;
		}
	}
}

.my-dashboard {
	label {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: $light-grey;
		text-transform: uppercase;
	}
	font-family: $font-family-rubik;
    .user-dashboard-table {
        max-height: 270px;
        overflow-y: auto;
    }
	.empty-order-box {
		.order-box {
			border: 1px $red;
			border-radius: 9px;
			border-style: dashed;
			.mh-400 {
				min-height: 350px;
			}
			.no-texts-ordered {
				font-family: $font-family-rubik;
				font-style: normal;
				font-weight: normal;
				font-size: $fontSize18;
				line-height: 21px;
				text-align: center;
				color: $blue;
				opacity: 0.5;
				margin: 0 0 1rem 0;
			}
			.order-now {
				font-family: $font-family-montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: $fontSize16;
				line-height: 20px;
				text-align: center;
				color: $red;
				border: 1px solid;
				padding: 9px;
				border-radius: 20px;
			}
		}
	}

	.newsfeed {
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: $light-blue;
		padding: 10px;
		p {
			font-weight: normal;
			color: $light-grey;
		}
        .blogs {
            .blog-img-box {
                height: 215px;
                width: 161px;
                overflow: hidden;
                border-radius: 4px;
            }
            .blog-img {
                height: 215px;
                // width: 100%;
            }
            .blog-heading {
                font-weight: 700;
                font-size: 16px;
                line-height: 22px;
                color: #2B4C6F;
                margin: 0;
            }
            p {
                padding: 0;
                margin: 3px 0;
            }
            .blog-content {
                font-weight: 400;
                font-size: 13px;
                line-height: 18px;
                color: #4A4A4A;
            }
        }
	}
}

.order-results {
	.box {
		border: 2px solid $alice-blue;
		border-radius: 5px;
		.pre-data {
			font-style: normal;
			font-size: 13px;
			line-height: 200%;
			tr {
				td {
					font-weight: 300;
					color: $black-2;
				}
				td ~ td {
					font-weight: 300;
					color: $black-2;
				}
				td ~ td ~ td {
					font-weight: normal;
					color: $blue;
				}
			}
		}
		.test-tesults-for {
			padding: 10px 5px;
		}
		.shipping-address {
			background-color: $alice-blue;
			padding: 10px 5px;
			.label {
				font-size: $fontSize16;
				font-style: normal;
				font-weight: 500;
				line-height: 19px;
				letter-spacing: 0em;
				color: $blue;
				margin-bottom: 10px;
			}
		}
	}

	.detection-panel {
		.outer-circle {
			border-radius: 50%;
			border: 10px solid $white;
			height: 170px;
			width: 170px;
			border-right: 10px solid $light-grey;
			border-left: 10px solid $light-grey;
			border-top: 10px solid $light-grey;
			padding: 10px;
			&.not-detected {
				border-right: 10px solid $light-blue;
				border-left: 10px solid $light-blue;
				border-top: 10px solid $light-blue;
			}
			&.detected {
				border-right: 10px solid $red;
				border-left: 10px solid $red;
				border-top: 10px solid $red;
			}
			.inner-circle {
				background-color: $white;
				padding: 5px;
				height: 130px;
				width: 130px;
				background: linear-gradient(0deg, $white, $white),
					linear-gradient(0deg, $white, $white), $white;
				box-shadow: 0px 0px 10px $light-grey;
				border-radius: 50%;
				&.not-detected {
					box-shadow: 0px 0px 10px $light-blue;
					border: 1px solid $light-blue;
				}
				&.detected {
					box-shadow: 0px 0px 10px $red;
					border: 1px solid $red;
				}
				.inner-name {
					font-size: $fontSize14;
					font-style: normal;
					font-weight: 500;
					line-height: 17px;
					letter-spacing: 0em;
					text-align: center;
					color: #2b4c6f;
					padding: 16px 0px;
				}
				.inner-status {
					white-space: pre-wrap;
					font-size: $fontSize16;
					font-style: normal;
					font-weight: 500;
					line-height: 19px;
					letter-spacing: 0em;
					text-align: center;
					color: $light-grey;
					&.not-detected {
						color: $light-blue;
					}
					&.detected {
						color: $red;
					}
				}
			}
		}
		.autoantibody {
			font-size: 13px;
			line-height: 15px;
			color: $light-grey;
		}
		.detection-panel-heading {
			font-size: $fontSize16;
			font-style: normal;
			font-weight: 400;
			line-height: 19px;
			letter-spacing: 0em;
			text-align: center;
		}
		.desc {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 15px;
			letter-spacing: 0em;
			text-align: center;
			color: $blue;
		}
	}

	.register-kit-stepper {
		border: 2px solid $alice-blue;
		border-radius: 5px;
		.left-box {
			text-align: center;
		}
		.stepper-box {
			min-height: 200px;
		}
		.step-result-box {
			font-family: $font-family-rubik;
			font-style: normal;
			text-align: center;
			padding-right: 1.5rem;
			h6 {
				color: $blue;
			}
			hr {
				text-align: center;
				width: 100%;
				color: #c6c6c6;
			}
			p {
				font-weight: 300;
				text-align: left;
				font-size: 13px;
				line-height: 125%;
				color: $light-grey;
				&.recommend {
					color: $light-blue;
				}
				a {
					color: $red;
				}
			}
		}
		.register-kit {
			border: 2px solid $alice-blue;
			border-bottom-left-radius: 5px;
			border-top-left-radius: 5px;
			background-color: $alice-blue;
			min-height: 200px;
			p {
				font-size: 14px;
				font-weight: 300;
				font-family: 'Rubik';
				color: $light-grey;
				a {
					font-style: italic;
					color: $light-blue;
				}
				.instruction {
					font-style: normal;
				}
				.track-number {
					color: $blue;
				}
			}
			.check-img {
				width: 40px;
			}
			.register-kit-btn {
				font-family: $font-family-montserrat;
				font-style: normal;
				font-weight: 500;
				font-size: $fontSize16;
				line-height: 20px;
				text-align: center;
				color: $white;
				border: 1px solid;
				border-radius: 50px;
				background-color: $red;
				border-color: $red;
				&:disabled {
					color: $light-grey;
					border-color: $light-grey;
					cursor: not-allowed;
					background-color: rgba(137, 136, 138, 25%);
				}
			}
			.register-btn-transparent {
				background-color: transparent;
				border-color: $red;
				color: $red;
			}
		}
	}
}

.custom-stepper {
	font-family: $font-family-rubik;
	.MuiStepLabel-labelContainer {
		font-size: $fontSize16;
		font-weight: 300;
		line-height: 19px;
		letter-spacing: 0em;
		text-align: center;
		.MuiStepLabel-label {
			&.Mui-active,
			&.Mui-disabled {
				color: #9c9c9c;
			}
			&.Mui-completed {
				color: $light-blue;
			}
		}
	}
}

.sidebar-item {
	position: relative;
	.toggle-cursor {
		// transform: rotate(180deg);
		display: none;
		transition: all 0.3s ease-out;
	}
	.sidebar-childs {
		display: none;
		position: absolute;
		bottom: -50px;
		width: 100%;
		height: 100%;
		left: 0;
		.sidebar-child {
			span {
				font-size: 13px;
			}
			&.sidebar-child-selected span {
				color: $red;
			}
            span {
				text-align: center;
			}
		}
	}
}

.sidebar-item .list-item-selected {
	.toggle-cursor {
		// transform: rotate(0deg)!important;
		display: initial;
	}
	.sidebar-childs {
		display: initial !important;
	}
}
.red-underline {
	position: relative;
	display: inline-block;
	&:after {
		content: '';
		width: 100%;
		position: absolute;
		height: 3px;
		left: 0;
		bottom: -100%;
		background: $red;
	}
}

.MuiTable-root {
	font-family: $font-family-rubik;
	font-style: normal;
	font-weight: normal;
	font-size: 11px;
	line-height: 150%;
	align-items: center;
	.MuiTableHead-root {
		.MuiTableCell {
			color: #e43638;
		}
	}
	.MuiTableBody-root {
		color: #89888a;
	}
}

.records-found {
	font-family: $font-family-montserrat;
	font-size: 12px;
	line-height: 15px;
	display: flex;
	align-items: center;
	float: left;
	padding: 15px 0;
}

.custom-pagination {
	font-family: $font-family-montserrat;
	margin-bottom: 2rem;
	.record-total {
		font-size: $fontSize12;
		font-weight: 400;
		color: $light-grey;
	}
	li {
		// padding: 10px;
	}
	button {
		font-style: normal;
		font-weight: 400 !important;
		font-size: 16px;
		line-height: 20px;
		align-items: center;
		text-align: center;
		color: #89888a;
		background-color: #fff;
		border: 1px solid;
		border-radius: 5px;
		width: 25px;
		height: 25px;
		&.prev,
		&.next {
			display: none;
		}
		&.active {
			color: #e43638;
			background-color: #fff;
		}
	}
}

.MuiNativeSelect-icon.MuiNativeSelect-iconOutlined {
	display: none;
}

label[for='image-product'] {
	display: inline-flex;
	align-items: flex-start;
}
.product-image-container {
	width: 80px;
	height: 80px;
	border: 1px solid;
	margin: 0 1rem 1.6rem 1rem;
	display: table;
	border-radius: 12px;
	cursor: pointer;
	.product-image-item {
		display: table-cell;
		vertical-align: middle;
		margin: auto;
		border-radius: inherit;
	}
	svg,
	img {
		display: table-cell;
		/* vertical-align: middle; */
		margin: auto;
		border-radius: inherit;
	}
}

.order-details,
.dashboard-card {
	.pre-data {
		font-family: $font-family-rubik;
		font-style: normal;
		font-size: 13px;
		line-height: 19px;
		color: $blue;
		tr {
			.pre-data-label {
				color: $blue;
				width: 180px;
			}
			.pre-data-card-text {
				color: $black-2;
				font-size: $fontSize14;
				line-height: 30px;
				width: 90%;
			}
			.pre-data-card-count {
				width: 20%;
				font-size: $fontSize14;
				&.text-red {
					color: $red;
				}
				&.text-blue {
					color: $blue;
				}
			}
			.pre-data-colon {
				width: 20px;
			}
			.pre-data-value {
				font-weight: 300;
				color: $black-2;
			}
			.pre-data-value {
				font-weight: 300;
				color: $black-2;
			}
			.pending-approval {
				font-weight: 300;
				color: $red;
			}
		}
	}
    .heading.hd-edt-wrap{
        display: flex;
        justify-content: space-between;
        .edit-btn{
            color: #2b4c6f;
        }
    }
}

.dialog-box {
	.dialog-box-title {
		font-family: $font-family-rubik;
		font-style: normal;
		font-weight: 500;
		font-size: $fontSize30;
		line-height: 36px;
		text-align: center;
		color: $blue;
	}
}
.loader img {
	width: 55px;
}
.MuiListItem-gutters[role='option'] {
	color: #000 !important;
}
.loader {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: 2rem 0;
}

.fancy-spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 5rem;
	height: 5rem;
	div {
		position: absolute;
		width: 4rem;
		height: 4rem;
		border-radius: 50%;
		&.ring {
			border-width: 0.5rem;
			border-style: solid;
			border-color: transparent;
			animation: 2s fancy infinite alternate;
			&:nth-child(1) {
				border-left-color: $blue;
				border-right-color: $blue;
			}
			&:nth-child(2) {
				border-top-color: $blue;
				border-bottom-color: $blue;
				animation-delay: 1s;
			}
		}
		&.dot {
			width: 1rem;
			height: 1rem;
			background: $blue;
		}
	}
}
@keyframes fancy {
	to {
		transform: rotate(360deg) scale(0.5);
	}
}

.clinician-my-dashboard {
	font-family: $font-family-rubik;
	label {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 17px;
		color: $light-grey;
		text-transform: uppercase;
	}
}

.order-status-label {
	border: 1px solid;
	border-radius: 10px;
	padding: 1px 10px;
	display: block;
    text-align: center;
    &.order-done {
		color: $order-done-color;
	}
	&.order-approved {
		color: $order-approved-color;
	}
    &.inprogress{
        background-color: #e0e0e0;
        color: #a6a6a6;
    }
}

.MuiBadge-root {
	&.role-badge,
	&.blocked-badge {
		.MuiBadge-badge {
			min-width: 80px;
			min-height: 25px;
			border-radius: 1px;
		}
	}
	&.order-status-badge {
		.MuiBadge-badge {
			box-sizing: border-box;
			min-width: 75px;
			border: 0.5px solid;
		}
		&.order-done {
			background: $order-done-bg;
			color: $order-done-color;
		}
		&.order-approved {
			background: $order-approved-bg;
			color: $order-approved-color;
		}
	}
}
.order-details {
	.download-icons-main {
		p {
			margin-right: 1.5rem;
			font-size: 12px;
			font-weight: 400;
			line-height: normal;
			span {
				margin-right: 0.5rem;
				width: 14px;
				height: 14px;
			}
		}
		p.red-text {
			color: $red;
		}
		p.blue-text {
			color: $blue;
		}
		p.skyblue-text {
			color: $light-blue;
		}
	}
}
.table-main {
	overflow-x: auto;
}

.header-menu-items > div {
	text-align: right;
}
.order-status-mesage {
	color: #2b4c6f;
}

.inventory-management {
	.input-group {
		margin: 0 10px;
		input, select {
			height: 25px;
			box-shadow: none;
			border-color: #ced4da;
			border-left: none;
			background-color: transparent;
		}
		.input-group-text {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			height: 25px;
		}
	}
	.quantity-required {
		display: flex;
		select {
			width: 50px;
			height: 25px;
			background-color: transparent;
			margin: 0 4px;
			padding: 0.1rem 0.5rem;
		}
		.input-group {
			width: 75px;
		}
		.orders-exceeding {
			font-size: $fontSize14;
			color: $black-2;
			margin-top: 2px;
		}
	}

	.confirm-delivery-details {
		.input-group {
			width: 100%;
		}
	}
}

.reg-kit{
    color: #207DC8;
    margin-left: 5px;
    font-weight: 500;
}

select.minimal {
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

.survey-tooltip{
    font-size: 14px;
    text-align: center;
    padding: 10px 0;
    i{
        float: right;
        color: #fff;
    }   
}

.button-wrap{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 15px 10px;
}

.icons-buttons-wrap{
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    .button-options-wrap{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        & > *{
            margin-left: 1rem;
        }
    }
}