// @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
// Primary Font
$primary-font: 'Lato', sans-serif;
$primary-background-color: #ffffff;
$primary-text-color: #000000;
$secondary-text-color: #8c848f;
$primary-color: #447edf;
$primary-placeholder: #aaa;

.App {
	// Common CSS
	h1.page-header {
		font-family: $primary-font;
		font-size: 31px;
		font-style: normal;
		font-weight: 900;
		letter-spacing: 0em;
		color: $primary-text-color;
		margin-top: 40px;
	}

	.primary-button {
		background-color: $primary-color;
		color: white;
		font-family: $primary-font;
		font-weight: 400;
		border-radius: 400px;
		padding: 1rem 3rem 1rem 3rem;
		border: none;
		align-items: center;
		font-size: 0.9rem;
		font-family: $primary-font;
	}

	.makeStyles-toolbar-3 {
		height: 73px;
	}

	.welcomeText {
		color: #89888a;
	}

	.adminNameText {
		color: #2b4c6f;
	}

	// Admin Panel CSS
	.admin-login {
		height: 100vh;
		width: 100%;
		display: flex;
		align-items: center;
		.adminLoginImg {
			width: 100%;
		}
		.admin-form-grid {
			form {
				width: 70%;

				.form-bottom {
					display: flex;
					justify-content: space-between;
					margin-top: 20px;
					margin-top: 20px;

					.login-button {
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: #363636;

						img {
							margin-left: 8px;
						}
					}
				}

				.forgot-password {
					margin-top: 20px;

					a {
						font-family: $primary-font;
						font-size: 13px;
						line-height: 15px;
						color: #ff3b3b;
					}
				}
			}
		}

		background-color: $primary-background-color;

		p {
			color: $secondary-text-color;
			margin-bottom: 35px;
		}

		.groupForm {
			margin-bottom: 20px;
			font-size: 14px;
			display: flex;
			width: 100%;
			justify-content: flex-end;
			.langSelector {
				padding: 1.2rem 2rem 0.5rem 1rem;
				border-radius: 31px;
				font-size: 14px;
				background: #fff;
				&:focus {
					outline: none;
				}
			}
			label {
				position: absolute;
				left: 21px;
				top: 6px;
				font-size: 11px;
				color: #828282;
				font-weight: 700;
			}
		}
	}

	// Forgot Password Page CSS
	.forgot-password-panel {
		height: 100vh;
		width: 100%;
		display: flex;
		align-items: center;
		.adminLoginImg {
			width: 100%;
		}
		.password-form-grid {
			form {
				width: 70%;
				padding: 0px 2rem;

				.form-bottom {
					display: flex;
					justify-content: flex-end;
					margin-top: 20px;
					margin-top: 20px;

					.login-button {
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							margin-left: 8px;
						}
					}

					.forgot-button {
						background-color: #363636;

						span {
							margin-right: 5px;
						}
					}
				}

				.forgot-password {
					margin-top: 20px;

					a {
						font-family: $primary-font;
						font-size: 13px;
						line-height: 15px;
						color: #ff3b3b;
					}
				}
			}
		}

		background-color: $primary-background-color;

		p {
			color: $secondary-text-color;
			margin-bottom: 35px;
		}
	}

	// Projects CSS
	.projects {
		.MuiDrawer-paperAnchorLeft {
			background-color: $primary-background-color;
		}

		.app-bar-logo {
			img {
				width: auto;
				padding: 10px 1rem;
			}
		}

		// .MuiListItemIcon-root {
		//   color: #ffffff;
		// }
		// .MuiTypography-root:not(.list-item-selected) {
		//   color: #ffffff;
		//   font-family: $primary-font;
		//   &:hover {
		//     color: #fff;
		//   }
		// }
		.MuiDivider-root {
			background-color: $primary-color;
		}

		.MuiToolbar-root {
			background-color: white;

			.MuiButtonBase-root {
				color: #447edf;
				background: transparent;
				font-size: 100px;
				font-weight: 900;
				-webkit-text-stroke: aqua;
				margin-left: 60px;
			}

			.MuiSvgIcon-root {
				fill: currentColor;
				width: 1em;
				height: 1em;
				display: inline-block;
				font-size: 1.8rem;
			}
		}
	}
}

.sidebar {
	.MuiButton-root {
		text-transform: capitalize !important;
	}

	img {
		margin: 0 0.6rem;
	}
}

.admin-routes-container {
	position: absolute;
	top: 155px;
	transition: all 0.2s ease-out;
	background: #ebebeb;
}

.breadcrumb-custom {
	position: fixed;
	top: 75px;
	z-index: 99;
	transition: all 0.2s ease-out;
	// background: #fff;
	display: block;
	width: 100vw;
	padding: 1rem;
	text-transform: capitalize;
	left: 240px;
	.pageHead {
		font-weight: 500;
		font-size: 18px;
		color: #2b4c6f;
	}
	a {
		color: #9c9c9c;
		font-family: Rubik;
		font-size: 14px;
	}
}

.MuiListItem-root:not(.list-item-selected) {
	color: #fff;
}

.list-item-selected {
	/* Increase the specificity */
	background-color: #eff7fe !important;

	& > * {
		color: #2b4c6f !important;
	}

	.MuiListItemText-root {
		color: #2b4c6f !important;
	}
}

.toolbar-main {
	display: flex;
	justify-content: space-between;
	width: 100%;

	.toolbar-left {
		display: flex;
		align-items: center;
		.fillSideNavBar {
			display: none;
		}
	}

	.toolbar-right {
		color: #000;
		text-align: center;
		text-transform: capitalize;
		padding-top: 6px;
		margin: 0 20px;

		span {
			font-weight: bold;
		}
	}
}

.card-main-custom {
	font-family: $primary-font;

	.row.header-row {
		margin: 0 0 3.5rem 0;

		.importText {
			margin-right: 30px;
			font-size: 15px;
		}
	}

	h2 {
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		// padding: 0 1rem;
		margin: 0;
	}

	.search-container {
		padding: 0;
		position: relative;
		margin-right: 0;

		.search-bar {
			font-size: 14px;
			color: #89888a;
			border: none;
			border: 1px solid #d9d9d9;
			outline: none;
			width: 100%;
			padding: 10px 45px 10px 10px;
			border-radius: 4px;
		}

		i.fas.fa-search {
			position: absolute;
			right: 3%;
			font-weight: 600;
			color: $primary-color;
			top: 35%;
			cursor: pointer !important;
		}
	}
	i.fas.fa-plus {
		margin-right: 0.5rem;
	}

	.card-pagination {
		margin: 1rem;
		float: right;
		width: 100%;
		.record-total {
			color: #89888a;
			font-size: 12px;
			margin: 1.7rem 0 0 1rem;
			display: inline-block;
		}
		button {
			border: 1px solid #aaa;
			padding: 0.2rem 0.6rem;
			font-size: 14px;
			background: transparent;
			margin-right: 0.5rem;
			outline: none;

			&.prev {
				border-top-left-radius: 25px;
				border-bottom-left-radius: 25px;
			}

			&.next {
				border-top-right-radius: 25px;
				border-bottom-right-radius: 25px;
			}
		}
	}
}

.card-main-content {
	.editLink {
		color: #444444;
		text-decoration: none;
	}
}

.on {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #000000;
	margin-top: 3px;
}

.off {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background: #aaa;
	margin-top: 3px;
}

.MuiButton-containedSecondary:hover {
	background-color: $primary-color !important;
}

.details-header {
	display: flex;
	justify-content: space-between;
	position: relative;

	h2 {
		font-size: 24px;
		margin: 0 0 5px 0;
		font-weight: 900;
		a {
			font-size: 14px;
			font-weight: normal;
			margin-left: 1rem;
		}
	}

	h2.id {
		color: #818181;
		font-size: 34px;
		font-weight: 300;
	}

	&:after {
		content: '';
		position: absolute;
		background: #cfcfcf;
		width: 100%;
		height: 1px;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
}

.blocked-label {
	display: block;
	margin: 0 0.5rem;
	color: $primary-placeholder;
	font-weight: bold;
}

.form-floating > label {
	color: $primary-placeholder;
	font-weight: bold;
	left: 15px;
}

.form-floating > .form-control,
.form-floating > .form-select {
	padding: 1rem 1.7rem;
	font-size: 0.8rem;
	padding-top: 1.5rem;
}

.popover-confirm {
	float: right;

	img {
		cursor: pointer;
	}
}

// inplace confirm popover
.popover {
	z-index: 99999;
}

.popover-header {
	background: $primary-color;
	color: #fff;
}

.popover-body button {
	margin: 0 0.5rem;
	padding: 0 0.5rem;
	border: none;
}

.custom-file-loader {
	border: 1px solid #ebebeb;
	border-radius: 400px;

	label {
		padding: 1rem 1.7rem;
		cursor: pointer;
		color: #aaa;
		font-weight: bold;
		width: 100%;
		height: 100%;
		/* display: block; */
	}
}

.img-preview {
	width: 80px;
	cursor: pointer;

	img {
		width: inherit;
	}
}

.report-details {
	display: flex;
	justify-content: space-between;
	align-items: center;

	h2 {
		font-size: 24px;
		margin: 11px 0 0 0;
	}

	.report-details-area {
		p {
			font-size: 23px;
			text-transform: capitalize;
			margin: 0 7px 0 0;
			position: relative;

			&:after {
				position: absolute;
				content: '';
				right: -7px;
				width: 2px;
				height: 25px;
				background: #ccc;
				top: 5px;
			}
		}

		.area-details {
			color: #444;

			span {
				position: relative;
				margin: 0px 7px;

				&:after {
					position: absolute;
					content: '';
					right: -8px;
					width: 2px;
					height: 25px;
					background: #ccc;
					top: -2px;
				}
			}
		}
	}

	.report-details-area {
		display: flex;
		align-items: center;
	}
}

h2.projects-name-count {
	display: flex;
	flex-direction: column;
	text-transform: capitalize;

	span {
		font-family: $primary-font;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: #848484;
		opacity: 0.71;
	}
}

.activity-tasks-container {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	gap: 1rem;
	/* border: 1px solid; */
	border: 1px solid rgba(68, 126, 223, 0.1);
	box-sizing: border-box;
	border-radius: 10px;
	padding: 1rem;
	margin-bottom: 15px;

	&:hover {
		border-color: #ccc;
	}

	img {
		width: 140px;
		border-radius: 20px;
		margin-right: 20px;
		height: 122px;
	}

	.card-inner-content {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
		width: 100%;

		p {
			margin: 0;
		}
	}

	.card-inner-header {
		display: flex;
		justify-content: space-between;
		height: 30px;

		strong {
			text-transform: capitalize;
		}
	}

	.card-inner-time-details {
		display: flex;
		gap: 1rem;
		text-align: end;
	}

	span.card-inner-time-details-item {
		display: flex;
		gap: 0.4rem;
		margin-right: 1rem;

		&:last-child {
			margin-right: 0;
		}

		p {
			color: #afafaf;
			font-size: 13px;

			span {
				color: #000;
			}

			i {
				margin-right: 3px;
			}
		}
	}

	.card-inner-content {
		display: flex;
		flex-direction: column;
		gap: 0.2rem;
	}

	span.card-inner-task-status-item {
		i {
			margin-left: 0.7rem;
		}

		// .visitLogReason{
		//   margin: 0 0 0 0.7rem;
		// }
	}
}

.visits-container {
	display: flex;

	span {
		display: inline-block;
		gap: 0.5rem;
		width: 100%;
		font-size: 14px;

		p {
			color: #afafaf;
			margin-right: 0.8rem;
		}
	}
}

.worker-info {
	p.light {
		color: #7e7e7e;
		margin: 0;
	}

	strong {
		margin-left: 5px;
	}
}

.dialog-container {
	display: flex;

	img {
		align-self: center;
		max-width: 70%;
		margin-right: 0.8rem;
	}

	.dialog-time-details {
		margin: 0.5rem 0;

		span {
			color: #afafaf;
			margin: 0 0.4rem;

			strong {
				color: #000;
				text-transform: capitalize;
			}
		}
	}

	span.card-inner-task-status-item {
		margin-right: 0.5rem;

		i {
			margin: 0 0.4rem;
		}
	}

	.content-right strong {
		text-transform: capitalize;

		margin: 0.4rem 0;
	}

	.dialog-content-details {
		margin: 0.4rem 0;
	}
}

.select-container select {
	width: 100%;
	height: 54px;
	border-radius: 400px;
	background: #fff;
	color: #aaa;
	outline: none;
	font-weight: bold;
	padding: 1rem 1.7rem;
	border: 1px solid #ebebeb;
	&:disabled {
		background: #e9ecef;
	}
}

img.project-list-image {
	height: 15px;
}

div.ReactTags__tags {
	position: relative;

	.ReactTags__selected {
		display: flex;
		flex-wrap: wrap;

		.tag-wrapper {
			background: #447edf;
			color: #fff;
			padding: 0.5rem;
			outline: 1px solid #fff;
			outline-offset: -5px;
			display: flex;
			align-items: center;
			margin-bottom: 15px;

			button.ReactTags__remove {
				border: none;
				border-radius: 50%;
				background: red;
				color: white;
				font-size: 0.9rem;
			}
		}
	}
}

/* Styles for the input */
div.ReactTags__tagInput {
	width: 200px;
	border-radius: 2px;
	display: inline-block;
	order: -1;
	margin: 0 12px 0 0px;

	input {
		border-radius: 400px;
		color: #aaa;
		font-weight: bold;
		left: 15px;
	}
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
	height: 31px;
	margin: 0;
	font-size: 12px;
	width: 100%;
	border: 1px solid #eee;
	padding: 0 12px;
	height: 40px;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
	border: 1px solid #ccc;
	background: #eee;
	font-size: 12px;
	display: inline-block;
	padding: 5px;
	margin: 0 5px;
	cursor: move;
	border-radius: 2px;
}

div.ReactTags__selected a.ReactTags__remove {
	color: #aaa;
	margin-left: 5px;
	cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
	position: absolute;
	z-index: 99999;
}

div.ReactTags__suggestions ul {
	list-style-type: none;
	box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
	background: white;
	width: 200px;
}

div.ReactTags__suggestions li {
	border-bottom: 1px solid #ddd;
	padding: 5px 10px;
	margin: 0;
}

div.ReactTags__suggestions li mark {
	text-decoration: underline;
	background: none;
	font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
	background: #b7cfe0;
	cursor: pointer;
}

.react-tags {
	margin-top: 0;
	// border: 1px solid #eee;
	padding: 0.7rem 1rem;
	border-radius: 400px;
	/* height: calc(3.5rem + 2px); */
	min-height: calc(3.5rem + 2px);
	display: flex;
	flex-direction: column;
	gap: 0.9rem;

	& button {
		margin: 0 0.1rem 0 0.9rem;
		padding: 0 0.4rem;
	}

	span {
		color: #aaa;
		font-weight: bold;
	}
}

.margin-0 {
	margin: 0 !important;
}

.addBtnStyle {
	width: 67px;
	height: 18px;

	font-family: $primary-font;
	font-weight: bold;

	/* identical to box height */

	color: #000000;
}

.drop-component-container .dropzone p {
	cursor: pointer;
}

.drop-component-container .dropzone {
	color: #ccc;
	outline: none;
	border: none;
	min-height: 100px;
}

.drop-component-container {
	height: auto;
	border: 1px solid #ced4da;
	text-align: center;
	padding: 1.2rem;
}

.drop-component-files img {
	height: 80px;
}

.drop-component-files ul {
	list-style-type: none;
	display: flex;
	flex-wrap: wrap;
	padding: 1rem 0;
	gap: 2.2rem;
	justify-content: center;
}

.drop-component-files ul li i {
	position: absolute;
	top: 2px;
	right: 2px;
	background: #fff;
	/* transform: translate(50%, 50%); */
	border-radius: 50%;
	/* padding: 4px; */
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.drop-component-files ul li i:hover {
	background-color: red;
}

.drop-component-files ul li {
	position: relative;
}

.sketch-light {
	color: #848484;
	font-size: 17px;

	a {
		font-size: 16px;
		font-weight: 400;
		color: #848484;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

.project-details {
	display: flex;
	flex-direction: column;

	.rightBorder {
		border-right: 1px solid #cfcfcf;
		margin-right: 7px;
	}

	.project-code {
		font-size: 14px;
		font-weight: 500;
		color: #444444;
	}

	.project-logo {
		display: flex;
		margin-bottom: 6px;

		h2 {
			border-right: 1px solid #cfcfcf;
			padding-right: 10px;
			font-size: 24px;
			font-weight: 900;
		}

		img {
			height: 30px;
			border-right: 1px solid #cfcfcf;
			padding: 0 10px;
		}

		a {
			padding: 0 10px;
			color: #444444;
			font-size: 14px;
			text-decoration: none;
			display: flex;
			align-items: flex-end;
		}
	}

	.project-count-values {
		span.count-head {
			// margin-right: 20px;
			.count-value {
				margin: 0 0.5rem;
				background: #363636;
				color: #fff;
				padding: 0.1rem 0.7rem;
				border-radius: 400px;
				font-size: 12px;
			}
		}
	}

	.projectDetails {
		margin-top: 8px;
		border-top: 1px solid #f1f1f1;
		padding-top: 8px;
	}
}

.project-worklogs-details {
	height: 30px;
	// overflow: hidden;
	display: flex;

	& > * {
		height: inherit !important;
	}

	img:not(.temp) {
		height: 30px;
	}

	.padded-border {
		border-right: 1px solid #cfcfcf;
		padding: 0 10px;

		.temp {
			margin-right: 7px;
		}
	}

	.paddedLeft {
		padding-left: 0 !important;
		position: relative;

		.project-code {
			position: absolute;
			left: 0;
			top: -7px;
			font-size: 14px;
		}
	}
}

.report-activity-card {
	h4 {
		margin: 0;

		span {
			display: flex;
			font-size: 14px;
			color: #909090;
			font-weight: normal;
		}
	}
}

.cardTask {
	margin-top: 10px;
	display: flex;

	.card-inner-content-details {
		width: 85%;
		display: flex;

		.teskInfo {
			width: 80%;
		}

		.taskWorkers {
			width: 20%;
			text-align: end;
		}
	}

	.card-inner-task-status {
		width: 25%;
		text-align: end;

		.taskWorkers {
			.worker-info {
				p,
				strong {
					font-size: 14px;
				}
			}
		}

		.card-inner-task-status-item {
			strong,
			i {
				font-size: 14px;
			}
		}
	}
}

.sketch-light-42 {
	font-family: $primary-font;
	font-weight: 300;
	font-size: 15px;
	line-height: 18px;
	color: #424242;
	text-transform: uppercase;
}

.app-version {
	// position: absolute;

	/* transform: translateX(-50%); */
	color: #2b4c6f;
	font-size: 12px;

	&.language-selector {
		left: 50%;
		display: flex;
	}
}

.auto-address{
    margin-bottom: 1.5rem;
    input{
        padding: 15px;
    }
}

@media screen and(min-width: 768px) and(max-width: 991px) {
	.breadcrumb-custom {
		nav {
			ol {
				li {
					a {
						font-size: 15px;
					}
				}
			}
		}
	}
	.card-main-custom {
		.projects-name-count {
			font-size: 18px;

			span {
				font-size: 13px;
			}
		}
	}
	.header-row {
		button {
			width: 80px;
			height: 40px;
		}
	}
	.card-main-content {
		table {
			thead {
				th {
					font-size: 13px;
				}
			}

			tbody {
				td {
					font-size: 12px;
				}
			}
		}
	}

	.details-header {
		h2 {
			font-size: 22px;
		}

		.id {
			font-size: 28px !important;
		}
	}

	.detail-controls {
		button {
			width: 120px;
			height: 55px;
		}

		.ReactTags__remove {
			width: unset;
			height: unset;
		}
	}
	.project-details {
		.project-code {
			font-size: 12px;
		}

		.project-logo {
			h2 {
				font-size: 18px;
			}
		}

		.projectDetails {
			.sketch-light {
				a {
					font-size: 14px;
				}
			}
		}
	}

	.card-main-custom {
		.header-row {
			margin: 0 0 2.5rem 0 !important;
		}
	}

	.react-tags {
		.ReactTags__selected {
			span {
				margin-bottom: 12px !important;
				margin-left: 0px !important;
			}

			.ReactTags__tagInput {
				margin-bottom: 12px;
			}
		}
	}

	.report-details {
		h2 {
			font-size: 18px;
		}

		.project-code {
			font-size: 12px !important;
		}

		.sketch-light {
			font-size: 14px;
		}
	}
	.activity-tasks-container {
		flex-direction: column;
	}
	.report-activity-card {
		h4 {
			font-size: 20px;
			margin: 0;
		}
	}
	.activity-tasks-container {
		img {
			margin-bottom: 15px;
		}

		.card-inner-header {
			margin-bottom: 4px;

			strong {
				font-size: 14px;
			}
		}

		.card-inner-time-details {
			span {
				font-size: 12px;
				display: block;
			}
		}

		.card-inner-task-status-item {
			i,
			strong {
				font-size: 14px;
			}
		}

		.card-inner-content {
			.worker-info {
				p,
				strong {
					margin-bottom: 4px;
					font-size: 13px;
				}
			}

			.visits-container {
				.card-inner-task-status-item {
					p,
					strong {
						font-size: 13px;
					}
				}
			}
		}
	}
	.cardTask {
		display: unset;

		.card-inner-task-status {
			width: 100%;
		}
	}

	.dialog-container {
		.content-right {
			.dialog-time-details {
				span {
					font-size: 12px;
					margin-left: 0;
				}
			}

			.card-inner-task-status-item {
				font-size: 14px;
			}
		}
	}
}
.details-component.container {
	margin-bottom: 15px;
}
.sidebar-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 80px;
	padding: 0 8px;
	width: 80%;
}
.lang-select {
	font-size: 12px;
	font-weight: 600;
	background: transparent;
	color: #fff;

	option {
		font-weight: inherit;
	}
}
.app-version.language-selector.form-group {
	margin-left: 15px;
}

@media screen and(min-width: 320px) and(max-width: 767px) {
	.admin-login {
		display: block;
		text-align: center;

		.adminLoginImg {
			display: none;
		}

		.admin-form-grid {
			form {
				margin: 0 auto;

				.login-button {
					padding: 1rem 1rem 1rem 1rem;
					font-size: 13px;
				}

				span {
					font-size: 11px !important;
				}
			}
		}

		.page-header {
			font-size: 28px !important;
			margin-top: 35px !important;
		}

		p {
			font-size: 14px;
		}
		.LoginForm {
			display: block;
		}
	}
	.forgot-password-panel {
		text-align: center;
		.password-form-grid {
			display: block;
		}
		.page-header {
			font-size: 28px !important;
			margin-top: 35px !important;
		}

		p {
			font-size: 14px;
		}

		.adminLoginImg {
			display: none;
		}

		form {
			margin: 0 auto;

			.forgot-button {
				padding: 1rem 1rem 1rem 1rem;
			}

			.form-bottom {
				text-align: center;
				display: block;
			}
		}
		.forgetForm {
			display: block;
		}
	}
	.toolbar-main {
		.toolbar-right {
			font-size: 13px;
		}
	}
	// .projects{
	//   ul{
	//     a > div > span,button{
	//       font-size: 13px;
	//     }
	//     button{
	//       font-size: 13px;
	//     }
	//   }
	// }

	.breadcrumb-custom {
		padding: 0.5rem;

		nav {
			ol {
				li {
					a {
						font-size: 13px;
					}
				}
			}
		}
	}
	.admin-routes-container {
		top: 140px;

		.details-header {
			margin-bottom: 25px !important;
			h2 {
				font-size: 15px;
			}

			.id {
				font-size: 18px;
			}
		}
	}
	.card-main-custom {
		.projects-name-count {
			font-size: 16px;

			span {
				font-size: 13px;
			}
		}

		.card-pagination {
			nav {
				ul {
					li {
						button {
							font-size: 11px;
							padding: 6px;
						}
					}
				}
			}
		}

		// .search-container {
		// 	.search-bar {
		// 		margin-top: 18px;
		// 		width: 100%;
		// 		padding-right: 16px;
		// 	}
		// 	i {
		// 		font-size: 13px;
		// 	}
		// }

		.header-row {
			margin: 0 0 1.5rem 0 !important;

			.sketch-light {
				font-size: 12px;
			}

			h2 {
				font-size: 18px;
			}

			.importText {
				font-size: 13px !important;
				margin-right: 12px !important;
			}
		}
	}
	.admin-routes-container > div {
		padding: 0rem;
	}
	.header-row {
		button {
			width: 75px;
			height: 35px;
			margin-top: 12px;
			font-size: 12px;
		}

		.project-details {
			.project-code {
				font-size: 13px;
			}

			.project-logo {
				h2 {
					font-size: 15px;
				}
			}

			.projectDetails {
				a {
					font-size: 13px;
				}

				.sketch-light {
					font-size: 13px;

					span {
						font-size: 11px;
						padding: 0.1rem 0.5rem;
					}
				}
			}
		}
	}
	.card-main-content {
		table {
			thead {
				th {
					font-size: 12px;
				}
			}

			tbody {
				td {
					font-size: 11px;
				}
			}
		}
	}
	.detail-controls {
		button {
			width: 80px;
			height: 40px;
			font-size: 12px;
		}

		.ReactTags__remove {
			width: unset;
			height: unset;
		}

		.project_logo {
			order: -1;
			margin-bottom: 8px;
		}

		.popover-confirm img {
			margin-top: 10px;
		}
		.toggleButton {
			order: 1;
		}
	}
	.report-details {
		display: block;

		h2 {
			font-size: 13px;
		}

		.dateText {
			text-align: start !important;
			margin-top: 15px;
		}
	}
	.project-worklogs-details {
		.paddedLeft {
			.project-code {
				font-size: 10px;
			}
		}

		.padded-border {
			font-size: 10px;

			.sketch-light {
				font-size: 10px;
			}
		}

		.sketch-light {
			font-size: 13px;
		}
	}
	.report-activity-card {
		h4 {
			font-size: 14px;

			span {
				font-size: 11px;
			}
		}
	}
	.activity-tasks-container {
		flex-direction: column;

		img {
			width: 100px;
			margin: 0 auto 10px;
		}

		.card-inner-time-details {
			display: block;

			span {
				p {
					font-size: 10px;
					margin: 0;
					br {
						display: none;
					}
				}
			}
		}

		.card-inner-content {
			.link {
				margin-top: 25px;
			}

			p {
				font-size: 13px;
				margin-top: 7px;
			}

			.card-inner-header {
				display: block;

				strong {
					font-size: 12px;
				}

				.left-heading {
					.sketch-light-42 {
						font-size: 12px;
					}
				}

				.sketch-light-42 {
					font-size: 12px;
				}
			}
		}
	}

	.cardTask {
		display: unset;

		.card-inner-task-status {
			width: 100%;
			text-align: start;

			.card-inner-task-status-item {
				strong,
				i {
					font-size: 12px;
				}
			}
		}

		.card-inner-content-details {
			width: 100%;
			display: block;
			margin-top: 18px;

			.teskInfo {
				font-size: 14px;
				width: 100%;
			}

			.taskWorkers {
				font-size: 12px;
				width: 100%;
				text-align: left;
				margin-top: 5px;
			}
		}
	}
	.details-component {
		.details-header {
			h2 {
				font-size: 18px;
			}
		}
	}
	.dialog-container {
		display: block;

		img {
			margin-bottom: 10px;
		}

		.content-right {
			.dialog-time-details {
				span {
					font-size: 12px;
					margin-left: 0px;
				}
			}

			.dialog-content-details {
				font-size: 14px;
			}

			.dialog-card-status {
				span {
					font-size: 12px;
				}
			}
		}
	}
	.select-container {
		select {
			margin-bottom: 1.9rem;
		}
	}
}
.headerLogo {
	margin-left: 5px;
}
@media screen and (max-width: 576px) {
	.toolbar-left {
		.headerLogo {
			display: none !important;
		}
	}
	.sideNavBar,
	.bigScreenToggle {
		display: none;
	}
	.fillSideNavBar {
		display: block !important;
		.fullNavToggle:focus {
			box-shadow: none;
		}
		nav {
			button {
				border-radius: 5px;
				padding: 5px 10px;
				margin: 0 !important;
			}
		}
	}
	.smallScreenNav {
		position: absolute;
		top: 115px;
		width: 100%;
		left: 0;
		img {
			margin: 0rem 0.6rem;
		}
		ul {
			background-color: #447edf;
			height: 100%;
		}
	}
	.app-version {
		bottom: 120px;
		position: absolute;
		left: 12px;
	}

	// .card-main-custom {
	// 	.search-container {
	// 		.search-bar {
	// 			width: 100%;
	// 		}
	// 	}
	// }
	.header-row {
		button {
			width: 60px;
			height: 30px;
		}
	}
	.breadcrumb-custom {
		left: 0px !important;
	}
	.admin-routes-container {
		width: 100%;
		left: 0% !important;
		padding: 0px 22px;
		> div {
			width: 100%;
		}
	}
	.app-version.language-selector {
		width: 100%;
		left: 0;
		display: block;
		margin-left: 0px !important;
		.lang-select {
			width: 70px;
			margin-right: 15px;
			float: right;
		}
	}
    .icons-buttons-wrap{
        flex-direction: column;
        .button-options-wrap{
            flex-wrap: wrap;
            justify-content: start;
            & > *{
                margin: 0;
                margin-top: 1rem;
                width: 70%;
            }
        }
    }
    .cancel-order-dialog{
        .button-wrap{
            flex-direction: column;
        }
    }
}

.sidebar-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 80px;
	padding: 0 8px;
	margin: 0 0.7rem;
}

.lang-select {
	font-size: 12px;
	background: transparent;
	color: #000;
	font-weight: 600;

	option {
		font-weight: inherit;
	}
}

.app-version.language-selector.form-group {
	margin-left: 15px;
}

.import-loader {
	max-height: 35px;
}

.pointer {
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}
.canvas-container {
	width: 1018px;
	position: fixed;
	top: -20000px;
	.canvas-header {
		display: flex;
		justify-content: space-between;
		.canvas-details {
			text-align: end;
		}

		img {
			width: 120px;
		}
		p {
			img {
				width: 110px;
			}
		}
	}
}

.c-dialog-actions {
	display: flex;
	justify-content: center;
	button {
		margin: 0 0.5rem;
	}
}
.c-dailog-content {
	text-align: center;
	padding-bottom: 1rem;
	font-size: 18px;
	color: #444444;
}

.activity-with-tree {
	display: flex;
	& > div:not(.tree) {
		width: 100%;
	}
}
.tree {
	min-width: 280px;
	overflow: auto;
	& + div {
		width: 100%;
	}
}
.super-treeview > div > .super-treeview-node {
	overflow: visible !important;
}
.detail-controls {
	& > div {
		overflow: auto;
	}
	input[type='checkbox'] {
		display: none !important;
	}
}
.detail-controls
	.super-treeview
	> div
	> .super-treeview-node
	> .super-treeview-node-content
	> label {
	text-overflow: initial !important;
	overflow: initial !important;
	cursor: pointer;
	&:hover {
		text-decoration: underline;
	}
}

.add-flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-control-textarea {
	resize: none;
}
label.fa-plus-span {
	margin-left: 12px;
	img {
		height: 60px;
		/* height: 80px; */
	}
	i {
		border: 1px solid #afafaf;
		padding: 22px;
		border-radius: 10px;
		cursor: pointer;
		color: #afafaf;
		background: #eee;
	}
}

.pdf-bulk-content {
	position: absolute;
	/* top: 40px; */
	width: 100%;
	left: -200%;
}
.hand {
	cursor: pointer !important;
	color: #447edf;
}

.custom-filter {
	// .filter{
	//     text-align: right;
	// }
	// .filter .flds-main{
	//     display:none;
	// }
	.filter {
		position: relative;
		.flds-main {
			position: fixed;
			right: 0;
			top: 0;
			background-color: #fffefe;
			z-index: 9999;
			flex-direction: column;
			height: 100vh;
			animation-name: zoomIn;
			animation-duration: 0.7s;
			animation-fill-mode: both;
			box-shadow: 0 1px 3px 1px #ccc;
			width: 320px;
			overflow-y: scroll;
			span.close-icon {
				box-shadow: 0 1px 3px 0 #ccc;
				margin-bottom: 1rem;
				position: sticky;
				right: 35px;
				top: 0px;
				background-color: #fff;
				width: 100%;
				z-index: 1;
				display: block;
				padding: 1rem;
			}
			.flds {
				padding: 0 1rem;
			}
			.sub-btn {
				padding: 1rem;
				box-shadow: 0 -1px 3px 0 #ccc;
				position: sticky;
				bottom: 0;
				background: #fff;
				left: 0;
			}
		}
	}

	@keyframes zoomIn {
		0% {
			width: 0;
		}
		100% {
			width: 320px;
		}
	}
}
div.inline-alert{
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin-bottom: 1rem;
    &.MuiAlert-standardSuccess{
        background-color:#def0d8;
    }
    &.MuiAlert-standardError{
        background-color: #f6e9e9;
    }
    ul{
        margin: 0;
        padding-left: 15px;
    }
}